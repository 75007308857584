product-stock-item {
    display: block;
    .job-name {
        @include noselect();
    }

    display: block;
    height: 6.2rem;
    clear: both;

    cursor: pointer;
    section {
        & > .job-amount {
            float: left;

            > div:last-child {
                height: 1.25rem;
            }
        }

        & > .job-amount,
        & > .job-name {
            padding-top: 2rem;
            padding-bottom: 1em;
            min-width: 4em;
        }

        // & > .job-amount,
        & > .job-name {
            display: block;
            margin-left: 8rem;
            line-height: 2rem;
            color: black;
        }

        .job-amount {
            padding: 1.2rem 0 0 0;
            // padding-bottom:.20em;
            margin-left: 0rem;
            text-align: center;
            margin-right: 0rem;
            font-weight: bold;
        }
    }

    article[disabled] {

        aside,
        section {
            & > .job-name,
            & > .job-amount,
            button {
                color:#bbb;
            }
        }
    }

    aside {
        text-align: center;
        padding-right: 1.25rem;
        //overflow: hidden;
        clear: both;
        float: right;
        button {
            padding: 1.25rem;
            background-color: transparent;
            border: none;
            color: black;

            &.dropdown-toggle .badge {
                right:1.2rem;
            }
        }

        job-taker {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            color: #777;
        }
    }

    button.lastclicked.saved{
        @include touchEffect();
    }
}