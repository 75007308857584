planned-job-group {
    display: block;
    border-radius: 2pt;

    & + planned-job-group {
        card {
            margin-top: 1.5rem;
            // border-top: 0.2rem solid #ccc;
        }
    }

    > card {
        display: block;
        ng-transclude {
            > header {
                display: block;
                padding: 0.8rem;
                /*
                padding-left: 0.8rem;
                padding-top: 0.6em;
                padding-bottom: 0.3em;
                */
                h2 {
                    font-size: 2.5rem;
                    margin: 0;
                    padding: 0;
                    color: #444;
                    span:first-child {
                        width: 7rem;
                        margin-right: 0.8rem;
                        text-align: left;
                        display: inline-block;
                    }
                }

                img {
                    width: 6rem;
                    height: 6rem;
                }
            }

            > section {
                border-top: 0.2rem solid #d0d0d0;
                position: relative;
                > header {
                    margin: 0 0.5rem;
                    position: absolute;
                    width: 100%;
                    top: -3rem;

                    font-weight: bold;
                    text-align: center;
                    padding: 0;
                    display: flex;
                    flex-direction: row;

                    color: #bbb;
                    font-weight: normal;

                    .date {
                        margin-left: 40%;
                        width: 10%;
                    }

                    .requirements {
                        width: 15%;
                    }

                    .planned {
                        width: 15%;
                    }
                }
            }

            planned-job + planned-job {
                border-top: 0.1rem solid #bbb;
            }
        }
    }
}
