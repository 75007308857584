category-list {
    display: block;
    ul {
        list-style-type: none;
        padding: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        li {
            width: 100%;
            white-space: nowrap;
            margin: 0;
            a {
                width: 100%;
                text-decoration: none;
                .category-name {
                    text-transform: capitalize; 
                    // text-transform: uppercase;
                    color: black;
                    font-family: "AvenirNext", "Arial";
                    font-size: 1.1em;
                    font-weight: bold;
                    padding: 1rem;
                    margin: 0;
                    margin-bottom: 1.5rem;
                    display: inline-block;
                    border-radius: 2em;
                }                
                .badge {
                    position: relative;
                    top: 0.5rem;
                    right: 0;
                    background-color: $secondary-dark;
                    font-family: "AvenirNext", "Arial";
                    font-size: 1em;
                    padding: 10px;
                    border-radius: 20px;
                    height: 40px;
                    width: 40px;
                    .count {
                        font-family: "AvenirNext", "Arial";
                        position: relative;
                        top: -0.2rem;
                        right: 1.05rem;
                        color: white;
                        font-weight:bold;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: center;
                        width: 40px;
                    } 
                }

                &.active,
                &:hover,
                &:focus {
                    .badge {
                        background-color: $primary;
                        color: $secondary-light;
                        text-decoration: none;
                    }
                    .category-name {
                        background-color: $primary;
                        color: $secondary-light;
                        text-decoration: none;
                    }
                }

            }

        }
    }
    
}
