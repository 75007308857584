haccp-icon {
    color:$red;
    position: absolute;
    display: inline-block;
    right:15em;

    div {
        text-align: center;        
        position: relative;
        top:-1rem;

        img {
            display: block;
            position: relative;   
            margin-left: auto;
	        margin-right: auto;
            height: 1.5em;
        }

        span {
            font-size: 1.2rem;
        }
    }
}