category {
    section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
            background-color: $secondary-light;
            margin-top: -0.5rem;
            padding: 0.2rem;
            margin-bottom: 0.5rem;
            width: 25%;        
            text-align: center;
            @media (orientation: portrait) {
                width: 33%;
            }
            @media (min-width:1900px){
                width: 20%;
            }
            img {                
                background-color: $secondary-light;
                border-radius: 20px;
            }

            .sub-category {
                background-color: $secondary-light;
                cursor: pointer;
                box-shadow: 0 0 1rem #ccc;
                background-image: url("/images/category.jpg");
                background-size: cover;
                font-family: "AvenirNext", "Arial";
                text-transform: capitalize; 
                // text-transform: uppercase;
                border-radius: 20px;
                margin: 0.8rem;

                &.active {
                    margin: 0;
                    border: 0.8rem solid $secondary-dark;
                }
                img {
                    filter: greyscale(0);
                    width: 100%;
                    vertical-align: text-bottom;
                }

                .label {
                    padding: 0 1rem;
                    display: block;
                    position: relative;
                    width: 100%;
                    margin-top: -10rem;
                    height: 10rem;
                    white-space: pre-wrap;
                    text-overflow:ellipsis;
                    background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
                    border-radius: 20px;

                    span {
                        position: absolute;
                        bottom: 5px;
                        left: 3px;
                        width: 95%;
                        text-align: center;
                        color: #fff;
                        font-size: 1.8rem;
                        font-weight: bold;
                    }
                }
            }

            .count {
                position: relative;
                float: right;
                margin-bottom: -56px;
                top: 15px;
                right: 15px;
                width: 50px;
                height: 50px;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
                border-radius: 25px;
                border: 25px solid $white;
                text-align: center;

                span {
                    position: relative;
                    top: -15px;
                    right: 21px;
                    color: $primary;
                    font-size: 2.5rem;
                    font-weight:bold;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                    text-align: center;
                    width: 40px;
                }
            }
        }
    }
}
