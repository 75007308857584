product-stock-group {
    display: block;

    > card {
        width: 100%;
    }

    & + product-stock-group {
        > card {
            margin-top: 1.5rem;
        }
    }

    header {
        display: block;
        padding-left: 1.6rem;
        padding-top: 0.6em;
        padding-bottom: 0.3em;
        border-bottom: 0.2rem solid #d0d0d0;

        h2 {
            font-size: 2.5rem;
            margin: 0;
            padding: 0;
            color: #444;
            span:first-child {
                width: 7rem;
                margin-right: 0.8rem;
                text-align: left;
                display: inline-block;
            }
        }

        img {
            width: 6rem;
            height: 6rem;
        }
    }

    product-stock-item + product-stock-item {
        border-top: 0.1rem solid #bbb;
    }

    product-stock-item {
        background-color: #fdfdfd;

        &:nth-child(even) {
            background-color: #f6f6f6;
        }
    }
}
