production {
    display: block;
    padding-top: 0.01rem;
    min-height: 92vh;
    height: 100%;
    background-color: $secondary-light;

    production-header {
        position: fixed;
        width: 100%;
        top: 7rem;
    }

    #job-groups {
        margin: 7rem 1.2rem 0 1.2rem;
    }

    spacer {
        position: relative;
    }

    production-date-indicator {
        padding-top: 37px;
        margin-right: 2px;
    }

    production-date-indicator>ul {
        margin-top: -2.5em;
    }

    card#dailyProduktionHeader {
        margin-top: 70px;
    }

    card {

        header {
            h1 {
                font-weight: bold;
                font-size: 1.3em;
                text-align: center;
                padding: 5px;
                color: $primary;
                background-color: $greyl;
                border-bottom: solid 3px $primary;
            }
            .preproductionHeader {
                font-weight: bold;
                font-size: 1.3em;
                text-align: center;
                padding: 5px;
                color: $white;
                background-color: $primary-light;
                border-bottom: solid 3px $darkgreen;
            }
        }
    }
}
