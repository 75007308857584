tablet-header {
    width: 100%;
    position: fixed;
    display: block;
    top: 0;
    background-color: $primary;
    color: $secondary-light;
    height: 6rem;
    z-index: 200;
    #hiddenMenu + .dropdown-menu {
        position: absolute;
        text-align: center;
        width: 100%;
        top: 200px;
        li a {
            font-size: 20px;
            line-height: 40px;
        }

        li + li {
            border-top: 0.1em solid $grey;
        }
    }

    connection-indicator {
        margin-left: 10px;
    }

    img.page-logo {
        width: 200px;
        position: absolute;
        top: 15px;
        left: 20px;
        margin: 0;
        padding: 0;
        z-index: 200;
    }

    #unauthorized {
        display: none;
        position: fixed;
        padding: 15em 5em 0 5em;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(100, 0, 0, 0.9);
        z-index: 10000;

        text-align: center;
        color: #fff;

        h1 {
            font-weight: bold;
            text-transform: capitalize; 
            // text-transform: uppercase;
            margin-bottom: 0.8em;
        }

        .actions {
            text-align: center;
            padding-top: 1.5em;
            .btn.btn-primary {
                min-width: 420px;
                background-color: #a00000;
                box-shadow: 0 0 5px #660000;
            }
        }
    }
}
