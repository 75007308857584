number-input {
    width: 100%;
    display: flex;
    @include shadow();

    button,
    input {
        background-color: #fff;
        text-align: center;
        border: none;
    }

    button {
        flex-grow: 0;
        display: inline-block;
        width: 4rem;
        font-weight: bold;

        &[disabled] {
            color: #ddd;
        }
    }

    input {
        width: 30px;
        flex-grow: 1;
    }
}
