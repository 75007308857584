production-job-header {
    display: block;
    height: 6.2rem;
    clear: both;

    cursor: pointer;
    section {
        & > .job-amount {
            float: left;

            > div:last-child {
                height: 1.25rem;
            }
        }

        & > .job-amount,
        & > .job-name {
            padding-top: 2rem;
            padding-bottom: 1em;
            min-width: 4em;
        }

        & > .job-name {
            display: block;
            margin-left: 8rem;
            line-height: 2rem;
            color: black;

            small {
                display: block;
                font-size: 0.8em;             
            }
            
        }

        .job-amount {
            padding: 1.55rem 0 0 0;
            // padding-bottom:.20em;
            margin-left: 0rem;
            text-align: center;
            margin-right: 0rem;
            font-weight: bold;

            time {
                position: relative;
                top: -1rem;
                color: #666;
                font-size: 1.25rem;
                font-weight: normal;
            }
        }

        .fake-badge {
            display: inline-block;
            color: #fff;
            text-transform: capitalize; 
            // text-transform: uppercase;
            padding: 0.2rem 0.8rem;
            margin-top: 2rem;
            margin-bottom: -0.3rem;
            background-color: #292728;
            i {
                font-size: 1.3rem;
                margin-top: 0.7rem;
                margin-left: 0.4rem;
            }
        }
    }

    aside {
        text-align: center;
        padding-right: 1.25rem;
        //overflow: hidden;
        clear: both;
        float: right;
        button {
            margin-left: 3rem;
            padding: 0.7rem 1.25rem;
            background-color: transparent;
            border: none;
            color: black;

            &[disabled] {
                color: #bbb;
            }

            span {
                display: none;
            }

            .badge {
                display: inline-block;
                width: 4rem;
                text-align: center;
                position: absolute;
                color: $primary-inverted;
                background-color: $primary-light;
                padding: 1px;
                right: 3.4rem;
                margin-top: 4rem;
                font-weight: bold;
                border-radius: 0.3rem;
            }

            .worker-count-badge {
                margin-right: 1.5rem;
                margin-top: -0.2em;
            }

            &.dropdown-toggle .badge {
                right:1.2rem;
            }
        }

        job-taker {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            color: #777;
        }
    }

    button.lastclicked.saved{
        @include touchEffect();
    }
    .dropdown.lastclicked{
        button.saved{
            @include touchEffect();
        }
    }
    
    .dropdown-toggle{
        .badge{
            top: -1.35em;
        }
    }

    .dropdown{
        display: inline;
        .dropdown-menu{
            left: unset;
            width: 10em;
            right: 0;
            min-width: 26rem;
            .dropdown-item{
                display: inline-block;
                width:3em;
                text-align: center;
                color:black;
                &.active{
                    color: $green;
                }
                font-size: 1.2em;
                font-weight: bold;
                padding: 0.5em 0.5em;
                border-right: 1px solid #888;
                &:last-child{
                    border-right: 0;
                }
            }

        }
    }

    .mhd-info {
        position: absolute;
        display: inline-block;
        right:31em;
        margin-top: -3px;
        font-size: 0.7em;
        padding: 5px;
        border-radius: 1em;
        -moz-outline-radius: 10px;
        border: 1px solid $primary;        
        color: $primary;
        min-width: 90px;
        text-align: center;
    }

    .remaining-notyet-doable {
        font-size: 0.8em;
        text-decoration-line: line-through;
        text-decoration-style: solid;
        text-decoration-color: $orange;  
        -moz-text-decoration-line: line-through;
        -moz-text-decoration-style: solid;
        -moz-text-decoration-color: $orange;                 
        color: $grey;
        padding: 1px 3px 1px 3px;
        border: 1px solid $orange;   
        border-radius: 1em;
        -moz-outline-radius: 5px;
    }
}
