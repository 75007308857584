hygenic-icon {
    color:$red;
    position: absolute;
    display: inline-block;
    right:28em;

    div {
        text-align: center;        
        position: relative;
        top:-0.6rem;

        img {
            display: block;
            position: relative;   
            margin-left: auto;
	        margin-right: auto;
            height: 1.5em;
        }

        span {    
            position: relative;
            top:0.1rem;
            font-size: 1.2rem;
        }
    }

}