toolbar>.toolbar-grid>refresh-button {
    button {
        padding: 1.2rem 2.2rem;
    }
}

refresh-button {
    button {
        background-color: transparent;
        padding: 1.8rem;

        i {
            font-size: 3rem;
            top: 0.5rem !important;

            &.loading {
                animation: rotate-animation 1.2s infinite linear;
                color: $secondary-dark;
            }

            @-o-keyframes rotate-animation {
                0% {
                    transform: rotate(0deg);
              }
              50% {
                    transform: rotate(180deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @-moz-keyframes rotate-animation {
                0% {
                    transform: rotate(0deg);
              }
              50% {
                    transform: rotate(180deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @-webkit-keyframes rotate-animation {
                0% {
                    transform: rotate(0deg);
              }
              50% {
                    transform: rotate(180deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }

    }
}