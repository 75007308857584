planned-job {
    display: block;
    background-color: #fdfdfd;    

    .job-name {
        padding-top: 1rem;
        cursor: pointer;
        @include noselect();
    }

    .clock {
        .typcn.typcn-stopwatch {
            color: $primary-light;
        }
        position: relative;
        top: -.5rem;
    }

    &.active {
        header,
        section {
            background-color: #fff;
        }

        number-input {
            input {
                color: $primary-light;
                font-weight: bold;
            }
        }

        header {
            calendar-icon {
                .typcn.typcn-calendar-outline {
                    opacity: 0.4;
                    color: $primary-light;
                }
            }
        }

        .job-actions a {
            color: $primary-light;
        }

        @include shadow();
        border-bottom: 0.2rem solid #666;
    }

    &:nth-child(even) {
        background-color: #f6f6f6;
    }

    > header {
        padding: 0 !important;
        display: flex !important;
        flex-direction: row;
        // border-right: 0.8rem solid grey;
        border-left: 0.8rem solid grey;

        &.unplanned {
            // border-right-color: grey;
            border-left-color: grey;
        }

        &.planned {
            // border-right-color: #54bb54;
            border-left-color: #54bb54;

            .job-actions {
                opacity: 0.5;

                a {
                    color: #54bb54;
                }
            }
        }

        a,
        a:hover,
        a:focus {
            color: black;
            text-decoration: none;
        }

        > *:not(:first-child) {
            border-right: 1px solid #eee;
            text-align: center;
            padding: 1.4rem 0 0rem 0;
        }

        .job-name {
            padding-left: 0.8rem;
            width: 40%;
        }

        calendar-icon {
            .typcn.typcn-calendar-outline {
                opacity: 0.2;
            }
            width: 10%;
            margin-top: -.1rem;
            margin-bottom: -.4rem;
        }

        .stock {
            width: 10%;
        }

        .requirements {
            width: 15%;
        }

        .planned {
            width: 15%;
        }

        .job-actions {
            margin-top: -.6rem;
            width: 10%;
            opacity: 0.2;
        }
    }

    &.active {
        .job-actions {
            opacity: 1;
        }
    }

    > section {
        display: flex;
        padding: 1.5rem 1.5rem 1.5rem 0;
        width: 100%;

        > div {
            width: 15%;
        }

        > timeline {
            width: 85%;
            .timeline-grid {
                @include shadow();
                > div {
                    flex-grow: 1;
                }

                .title,
                .value {
                    padding: 0.5rem;
                }

                .selected {
                    .value {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
