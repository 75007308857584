search-button {
    display: flex;
    flex-direction: row;
    padding: 1rem;

    color: $primary-inverted;

    input {
        flex-grow: 1;
        min-width: inherit;
        width: inherit;
        padding: 0 0.5rem;
        color: black;
    }

    > a {
        display: inline-block;
    }

    > a > i,
    i {
        padding: 1rem;
        width: 3rem;
        height: 3rem;
        margin: -1rem 0;
    }
}

toolbar > .toolbar-grid > search-button > a {
    display: inline-block;
    padding: 0;
}
