
.gauge-container {
    background-color: $primary;
    margin: 10px;
    padding: 8px;
    border-radius: 20px;
}

.gauge-header {
    background-color: $primary;
    border-radius: 5px;
    color: white;
    text-align: center;
    font-size: 1.5em;
    line-height: 1.3em;
    height: 40px;
    max-height: 40px;
    overflow: hidden;
}

.gauge-textValue {
    color: #333333;
    text-align: center;
    position: relative;
    top: -12px;
    height: 1px;
}

.gauge-body {
    background-color: white;
    text-align: center;
    padding: 15px 0 0 0;
}

.gauge-footer {
    background-color: $secondary;
    font-size: 0.75em;
    color: #333333;
    text-align: center;
    vertical-align: middle;
    padding: 3px;
    height: 70px;
    max-height: 70px;
    overflow-y: hidden;
    overflow-x: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}