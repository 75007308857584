stock-items {
    display: block;
    padding:1.8rem 0 1.8rem 0;
    background-color:#e0e0e0;

    card-content {
        padding:0;
    }

    #stockItemsContainer {
        margin: 4.8rem 1.2rem 0 1.2rem;
    }

    
    stock-item {
        background-color: #fdfdfd;

        &:nth-child(even) {
            background-color: #f6f6f6;
        }

        & + stock-item {
            border-top: 0.1rem solid #bbb;
        }
    }
}