user-info {
    display: inline-block;
    top: 0.7rem;
    position: relative;
    color: $secondary;
    
    a, a:hover, a:active, a:visited {
        color: $secondary;
    }

    i.typcn.typcn-user:before {
        position: relative;
        top: 0.01em;
        font-size: 3rem;
        color: $secondary-dark;
    }

    i.typcn.typcn-location:before {
        position: relative;
        top: 0.01em;
        font-size: 3rem;
        color: $secondary-dark;
    }

    .location {
        margin-right:5rem;        
        a, a:hover, a:active, a:visited {
            i {
                color: $secondary;
            }
        }
    }

    .username {

        ul {
            
            li {
                padding:1rem;
            }
        }
    }

    i {
        margin-right: 1.3rem;
    }
}
