ul[uib-pagination].pagination {
    > li.active > a {
        background-color:$primary;
        border-color:$primary;
        color:$white;
    }

    > li > a {
        color:$primary;
    }
}