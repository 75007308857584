toolbar {
    display: block;
    z-index: 100;
    position: fixed;
    width: 100%;
    margin-top: -.2rem;
    padding-left: 220px;
    top: 6.2rem;
    left: 0;
    background-color: $primary;

    > .toolbar-grid {
        display: flex;
        flex-grow: row;

        > * {
            text-align: center;
            text-transform: capitalize; 
            // text-transform: uppercase;
            display: inline-block;
            flex-grow: 0;
            background-color: $primary;

            + * {
                margin-left: 0.2rem;
            }
        }

        > spacer a.active {
            margin: -1.8rem 0;
            padding: 1.8rem 0;
        }

        > toggle-plattenliste-button > button {
            padding:2.8rem 1.8rem .6rem 1.8rem;
            display: block;
            width: 100%;
            background-color:transparent;
        }

        > spacer,
        > span,
        > * a,
        > * button {
            display: block;
            color: $secondary-light;
            font-weight: bold;
            padding: 1rem 0;
            border: none;
            text-transform: capitalize; 
            // text-transform: uppercase;
            text-decoration: none;
            outline: none;

            i.pull-right {
                @media (orientation: landscape) {
                    margin-right: 1rem;
                }
                margin-top: -.75rem;
            }
        }

        > * button,
        > spacer,
        > span {
            padding-top: 1.8rem;
            padding-bottom: 0;
        }

        > back-button,
        > home-button {
            padding-left: 1rem;
            padding-right: 1rem;

            i {
                position: relative;
                top: -.4rem;
            }
        }

        > home-button {
            width: 202px;
            margin-right: 0.45%;
            color: $secondary-light;
        }

        > back-button {
            width: 202px;
            color: $secondary-light;
        }

        > filter-button {
            width: 13.7%;
        }

        > spacer {
            > a {
                text-decoration: none;
                outline: none;
                &.active {
                    // font-size: 2.2rem;
                    // font-weight: bold;
                    color: $secondary-light;
                }
                padding: 1.8rem 0;
                margin: -1.8rem -1rem 0 -1rem;
            }
            display: inline-block;
            flex-grow: 1;
        }        
    }
}
