.table {
    tr {
        th,
        td {
            padding: 0.5rem 0.4rem;
        }
    }
}

.nav.nav-tabs {
    background-color: #fff;
    & > li.uib-tab {
        border: none;
        border-radius: 0;

        a {
            color: black;
        }

        &:hover a,
        &.active a,
        a,
        &:hover a {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background-color: #fff;
            border: none;
        }

        &.active a {
            background-color: #fff;
            color: #000;
            border: none;
            border-bottom: 0.4rem solid $primary-light;
            border-radius: 0;
            // border-bottom: none;
        }
    }
    margin-bottom: 0;
}

.tab-content {
    border-top: 0.1rem solid #888;
    border-top: none;
    background-color: #fff;
    min-height: 27.8rem;
    padding: 1.5rem;
    // padding:0 1.1rem;

    ul {
        padding: 0;
        list-style-type: none;
        li {
            padding-top: 1rem;
            padding-bottom: 1rem;

            .badge {
                font-size: 2rem;
                padding: 0.5rem 1rem;
                border-radius: 0.5rem;
                margin-right: 1rem;
                margin-bottom: 0.5rem;
                background-color: transparent;
                color: #444;
                font-weight: bold;
            }

            & + li {
                border-top: 0.1rem solid #ddd;
            }
        }
    }
}