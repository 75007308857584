job-taker {
    display: block;
    color:#222;
    text-align: center;

    div {
        &> div:first-child {
            color:#444;
            font-size: 1.5rem;
        }
        &> div:last-child {
            font-weight: bold;
        }
    } 
}