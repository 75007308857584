production-planning {
    display: block;
    padding: 1.7rem 0;
    min-height: 92vh;
    height: 100%;
    background-color: $secondary-light;

    card {
        category-list ul {
            margin: 0;
            padding: 0;
            background-color: #fff;

            li {
                padding-left: 0;
                padding-right: 0;

                a {
                    padding-left: 0;
                }
            }
        }

        width: 100%;

        + card {
            margin-top: 2rem;
        }

        card-content {
            padding-bottom: 0;
        }
    }

    > article {
        margin-top: 5.5rem;
        display: flex;

        > aside {
            width: 22.5%;
        }

        > section {
            margin-left: 23.5%;
            width: 75.5%;
        }
    }

    footer{
        width: 100%;
    }
}
