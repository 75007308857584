tablet-footer {
    display: block;
    
}


footer {
    position: fixed;
    right: 0 !important;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding-bottom:3rem;
    padding-right:2.7rem;

    @media (orientation: portrait) {
        bottom: 25px;
    }
}