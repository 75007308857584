my-city {
    display: block;
    padding-top: 0.01rem;

    .container {
        margin: 2rem;
        margin-top: 7.5rem;
        min-height: 82vh;
        width: 97vw;
    }

    .grid {        
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        > div {
            margin-top: -0.5rem;
            padding: 0.2rem;
            margin-bottom: 0.5rem;
            width: 25%;
            text-align: center;
            @media (orientation: portrait) {
                width: 33%;
            }
            @media (min-width:1900px){
                width: 20%;
            }
        }
    }

    .reports-mycity-spinner {
        position: absolute;
        top: 25vw;
        left: 45%;
    } 
    
    h1 {
        text-align: center;
    }
    
    .reportsHeaderBar {
        margin: 10px;
        padding:0.25em;
        border-radius: 6px;
    }
    
    .yearToggle {
        margin: 20px;
        float: right;
        label {
            font-size: 1.25em;
            font-weight: bold;
            color: #2E595F;        
        }
    }
    
}
