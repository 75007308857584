stock-item {
    display: block;
    padding: 15px;
    position: relative;

    .inputs {
        position: absolute;
        top:-1.5rem;
        display: block;
        
        select {
            padding:3px 4px;
            margin-right:12px;
        }

        input[type="number"] {
            
            text-align:center;
            max-width:100px;
        }

        .new-balance {
            padding-left: 2rem;
            display: inline-block;
            color:$orange;
        }

        .action {
            padding-left:2rem;
            display: inline-block;
            a {
                color:$green;
            }
        }

    }

    
    
}