.btn {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border-radius: 1em;
    background-color: lighten($primary, 25%);
    padding: 0.5em 0.5em 0 0.5em;
    border: 0.1em solid #fff;
    color: $black;
    &:hover {
        background-color: lighten($primary, 35%);
        border: 0.1em solid $primary;
    }
}

.av-button {
    border: none;
    background: white;
    padding: 0.5rem 2.5rem;

    &:focus {
        outline: none;
    }

    &.primary {
        border: 0.05em solid $primary;
        border-radius: 1em;
        background-color: $primary;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        color: #fff;
        &:hover {
            background-color: $primary-light;
        }
        &:focus, :active, :visited {
            outline: none;
            background-color: $primary;
        }
    }

    &.secondary {
        border: 0.05em solid $primary;
        border-radius: 1em;
        color: $primary;
        &:hover {
            background-color: lighten($primary, 40%);
        } 
        &:focus, :active, :visited {
            outline: none;
            background: white;
        }
    }

    &[disabled] {
        opacity: 0.5;
    }
}

.button-list {
    list-style-type: none;
    margin:0;

    li {
        .av-button {
            display: block;
            width:100%;
        }

        + li {
            margin-top:4rem;
        }
    }
}

.actions {
    text-align: right;
    margin-top: 3rem;
}
