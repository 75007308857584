@mixin hourState($name, $font, $border) {
    &.#{$name} {
        cursor: pointer;
        color: $font;

        border-bottom: 0.4rem solid $border;
        margin-bottom: -0.4rem;
    }
}

timeline {
    display: block;

    .timeline-grid {
        display: flex;
        flex-direction: row;

        > div {
            width: 7.65%;
            &:last-child() {
                width: 8.2%;
            }
            font-size: 1.5rem;
            text-align: center;
            color: #888;

            @include hourState("open", #000, transparent);
            @include hourState("required", #f88, #f44);
            @include hourState("selected", $primary-light, $primary-light);
            @include hourState("not-selectable", #ddd, transparent);

            .sum {
                font-weight: bold;
            }

            .partially-produced {
                color: $primary;
            }

            &:not(:last-child) {
                border-right: 0.1rem solid #ddd;
            }

            &.postino {
                color: #a6a;
            }

            > div:first-child {
                border-bottom: 0.1rem solid #eee;
            }
        }
    }
}
