production-job-content {
    display: block;
    padding: 1.5rem;
    border-bottom: 0.1rem solid #555;
    border-top: 0.1rem solid #ccc;

    .bug-button {
        border-left:.5rem solid $red;
        color: $red;
        display: block;
        margin:0;
        padding:.5rem;
        padding-left:1.5rem;
    }

    article {
        display: flex;
        flex-direction: row;
        aside {
            width: 25%;

            > div {
                padding: 0.5rem;

                @include shadow();

                background-color: #fff;

                position: relative;
                width: 100%;

                & + div {
                    margin-top: 1rem;
                }

                a {
                    color: $link;
                    &:hover {
                        color: $primary;
                    }
                }

                p {
                    margin: 0;
                    // font-size: 1.5rem;
                    color: #666;
                }             

                &.product-image-container {
                    padding-top: 100%;
                    position: relative;
                    .product-image {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-position: center;
                        background-size: cover;
                        border-radius: 2pt;
                    }

                    .new-corner {
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 1rem 1.5rem;
                        background-color: $red;
                        color: white;
                        border-bottom-left-radius: 1rem;
                    }
                }
            }

            > div#bugButtonContainer {
                padding:0;
            }

            img {
                width: 100%;
            }
        }

        section {
            width: 80%;
            padding-left: 1.5rem;
            recipe {
                @include shadow();
            }

            job-sums {
                margin-top:0.5rem;
                display: block;
                .table>thead>tr>th,
                .table>tbody>tr>td, {
                    border-bottom:0;
                    font-size: .9em;
                    padding:0;
                    color: #bbb;

                    &:last-child {
                        text-align: right;
                    }
                }                
            }
        }
    }
}
