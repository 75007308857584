
planned-time {
    width: 22.5%;
    position: absolute;
    left: 0;
    bottom: 2rem;
        
    card {
        div {
            margin-left: 20px;
        }
    }
}