production-job {
    display: block;

    &.active {
        box-shadow: 0 0 1rem darken($primary, 15%);
    }

    production-job-header {
        clear: both;
    }
    .job-name {
        @include noselect();
    }
}