@mixin spacing {

}

.modal-content {
    margin-top:10rem;
    h2 {
        font-size: 2.5rem;
        color:#444;
        margin-top:0;
        margin-bottom:0;

        
        @include spacing();
        margin-left:-1.5rem;
        margin-right:-1.5rem;
        padding:1.5rem 4rem 3rem 1.5rem;
    }

    .actions {
        margin-top:0;
        margin-left:-1.5rem;
        margin-right:-1.5rem;
        padding:1rem 1.75rem .6rem 1.5rem;
    }
    padding:2rem 4rem;
}