category-selection {
    display: block;  

    article {
        padding-top: 6rem;
        display: flex;        
        height: 92vh;

        > aside {
            padding-top: 3rem;
            background-color: white;
            display: block;
            width: 22.5%;            
            height: 100%;
        }

        > section {
            padding-top: 3rem;
            padding-left: 0.5rem;
            width: 77.5%;
        }

        @media (orientation: portrait) {
            > aside {
                width: 25%;
            }

            > section {
                width: 75%;
            }
        }
    }
}
