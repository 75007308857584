auth-location {
    display: block;
    text-align: center;
    margin-top:5em;
    background-color: $secondary-light;
    height: 92vh;
    color: white;
  
    h2 {
        color: $primary;
        margin: 0;
        padding: 3rem 0 0 0;
    }

    .location-list {
        margin: 0 2rem;
        padding: 2rem;
        image-tile {
            margin: 1.5rem;
        }
    }
}