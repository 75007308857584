/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 4rem;
    padding-top: 0.45rem;
    cursor: pointer;
}

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 3.2rem;
    height: 3.2rem;
    border: 0.2rem solid #ccc;
    background: #fff;
    border-radius: 0.4rem;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
    content: "✔";
    position: absolute;
    top: 0.5rem;
    // left: .4rem;
    left: -.5rem;
    font-size: 3rem;
    line-height: 2.3rem;
    color: $primary;
    transition: all 0.2s;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
    color: #999;
}
[type="checkbox"]:disabled + label {
    color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
    border: 0.2rem dotted $primary-light;
}

/* hover style just for information */
label:hover:before {
    border: 0.2rem solid $primary-light !important;
}
