personal-planning {
    display: block;
    padding-top: 0.01rem;

    .container {
        margin: 2rem;
        margin-top: 7.5rem;
        background-color: white;
        min-height: 82vh;
        width: 97vw;

        h2 {
            color: $primary;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        .align-left {
            text-align: left !important;
        }

        .personalTable {                       
            width: 100%;
            border-bottom: 1px solid #efefef !important;
            th {
                border-left: 1px solid white;
                color: white;
                text-align: center;
                font-size: 1.8rem;
                font-weight: normal;
                line-height: 4rem;
                height: 4rem;
                text-transform: capitalize; 
                // text-transform: uppercase;
                background-color: $primary;
            }
            tr {
                background-color: #efefef;
                &:nth-child(even) {
                    background-color: #f6f6f6;
                }
            }
            td {
                white-space: nowrap;
                padding: 1rem;
                border-left: 1px solid white;
                color: black;
                text-align: center;
                font-size: 1.8rem;
                text-transform: capitalize; 
                // text-transform: uppercase;
                &.required {
                    vertical-align: text-top;
                    span {
                        display: inline-block;
                        position: relative;
                        top: -1rem;
                        &.orange {
                            font-weight: bold;
                            color: darken($orange, 5%)
                        }
                    }
                    i.typcn {
                        padding: 0; 
                        margin: 0;
                        display: inline-block;
                        position: relative;
                        top: -0.9rem;
                    }
                }
            }
        }

        .pointer {
            cursor: pointer;
        }

        .detailRow {   
            background-color: #efefef !important;
            .align-left {
                background-color: white !important;
                border-left: 1px solid #efefef !important;
            }
            td {
                white-space: nowrap;
                color: $primary;
                font-size: 1.6rem;
                .ordered {
                    border: 1px solid green !important;
                    padding: 12px 4px 6px 8px;
                    vertical-align: bottom;
                    color: green;
                }
                button {
                    background-color: $orange;                    
                }
            }
        }
            
    }
}