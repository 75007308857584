goods-receiving-order {
    display: block;

    card {
        width: 100%;
    }

    #items {
        tr {
            td:last-child {
                border-right: 0.5rem solid grey;
            }

            &:nth-child(even) {
                background-color: #fafafa;
            }

            &.done td:last-child {
                border-right-color: green;

                i {
                    color: green;
                }
            }
        }

        a,
        a:hover,
        a:focus {
            top: -.7rem;
            position: relative;
            color: black;
        }
    }
}
