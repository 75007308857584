
$greyl: #c0c0c0;
$grey: #999;
$white: #fff;
$black: #222;
$darkgreen: #2E595F;
$green: #69be28;
$orange: #fca400;
$red: #e00034;

$blue: #063D66;
$beige: #f5ead4;
$beige-dark: #B29000;

$primary: #063D66;
$primary-light: lighten(#063D66, 15%);
$primary-dark: #001728;

$secondary: white;
$secondary-light: lighten(#B29000, 61%);
$secondary-dark: #B29000;

$primary-inverted: #fff;

$link: #620;
$border: 0.1rem solid #aaa;

@mixin color($name, $foreground) {
    .#{$name} {
        color: $foreground;
    }
}

@include color("white", $white);
@include color("green", $green);
@include color("orange", $orange);
@include color("red", $red);
@include color("blue", $blue);
@include color("beige", $beige);

