@mixin shadow($color: black) {
    box-shadow: 0 1px 3px rgba($color, 0.12), 0 1px 2px rgba($color, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@mixin hover() {
}

@mixin noselect() {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

@mixin touchEffect() {
    border-radius: 50%;
    animation: shadowFade 2s;
}
@-webkit-keyframes shadowFade {
    0% { background-color: white; }
    50% { background-color: #e0e6dc; }
    100% { background-color: white; }
}

@-webkit-keyframes shadowFadeOut {
    0% { background-color: white; }
    50% { background-color: #e0e6dc; }
    100% { background-color: white; }
}