filter-button {
    display: block;
    z-index: 200;
    position: relative;
    width: 75px;
    max-width: 75px;

    > button {
        background-color: transparent;
        width: 100%;
    }

    .glyphicon.glyphicon-cog {
        position: relative;
        top:-.25rem;
        font-size: 3rem;
    }

    production-settings {
        top: 100%;
        color: #000;
        position: absolute;
        right: 0;
        @include shadow();
        display: none;
        background-color: #fff;
        padding: 1rem;
        z-index: 100;
        text-align: left;

        &.active {
            display: block;
        }
    }

    span.badge {
        background-color:$red;
        padding:0.6rem 1rem;
        border-radius:50%;
        font-size: 1.5rem;
        position: relative;
        top:-1.75rem;
        left:-0.75rem;
    }
}
