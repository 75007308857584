production-date-indicator {
    font-size: 0.75em;
    color: white;


    & > ul {
        width: 7rem;
        list-style-type: none;
        margin-bottom: 0;
        // margin-top: -16px;
        padding: 0;
        background-color: $primary !important;
        border-radius: 0;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;

        // margin-top: -1.15em;
        margin-right: -2px;

        display: flex;
        flex-grow: row;

        > li {
            + li {
                border-left: none;
            }
            width: 100%;
        }
    }
    & > ul.notToday {
        background-color: $primary-dark !important;
        &:hover {
            background-color: $primary-light !important;
        }
    }

    day-selector{
        position: absolute;
    }
    day-selector:nth-child(2){
        right: 0;
    }
}
toolbar>.toolbar-grid>spacer>a{
    position: relative;
}
