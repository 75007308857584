@font-face {
    font-family: 'aveato-icons';
    src: url('../fonts/aveato-icons.woff');
    font-weight: normal;
    font-style: normal;
}

[class^="av-icon-"], [class*=" av-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'aveato-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /*line-height: 1;*/
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* insert icons here */

.av-icon-account:before {
    content: "\e900";
}

.av-icon-add:before {
    content: "\e901";
}

.av-icon-bagel:before {
    content: "\e902";
}

.av-icon-canape:before {
    content: "\e903";
}

.av-icon-check:before {
    content: "\e904";
}

.av-icon-coldkitchen:before {
    content: "\e905";
}

.av-icon-connected:before {
    content: "\e906";
}

.av-icon-crisped:before {
    content: "\e907";
}

.av-icon-defrosting:before {
    content: "\e908";
}

.av-icon-dessert:before {
    content: "\e909";
}

.av-icon-disconnected:before {
    content: "\e90a";
}

.av-icon-dispatching:before {
    content: "\e90b";
}

.av-icon-dummy:before {
    content: "\e90c";
}

.av-icon-filter:before {
    content: "\e90d";
}

.av-icon-home:before {
    content: "\e90e";
}

.av-icon-location:before {
    content: "\e90f";
}

.av-icon-pause:before {
    content: "\e910";
}

.av-icon-play:before {
    content: "\e911";
}

.av-icon-reload:before {
    content: "\e912";
}

.av-icon-salad:before {
    content: "\e913";
}

.av-icon-sandwich:before {
    content: "\e914";
}

.av-icon-start:before {
    content: "\e915";
}

.av-icon-status:before {
    content: "\e916";
}

.av-icon-wrap:before {
    content: "\e917";
}
