.tablet-nav {
    margin-top: 6em;
    background-color: #222;
    a.btn {
        background-color: #222;
        border: none;
        color: #e0e0e0;
        padding: 13px;
        display: block;
        &.active,
        &:focus,
        &:hover {
            background-color: #fca400;
        }
    }
}

.tablet-content-body {
    padding-top: 8rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 92vh;  
}

//nav.breadcrumb {
//    display: block;
//    width: 100%;
//    position: fixed;
//    bottom: 0;
//    margin-bottom: 0;
//    padding: 0;
//    padding-right: 1rem;
//}



.group-selection {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-right: 1rem;
    li {
        padding: 0;
        a {
            display: block;
            padding: 0.5em;
            color: #222;
            text-transform: capitalize; 
            // text-transform: uppercase;
        }
        .badge {
            margin-top: 1.25em;
            border-radius: 0;
        }
        &.active {
            background-color: $primary-light;
        }
    }
    li + li {
        border-top: 0.1em solid #e0e0e0;
    }
}

#inventory {
    padding-bottom: 3em;

    footer {
        margin-left: -15px;
        margin-right: -15px;
    }
}

footer {
    position: fixed;
    width: 100%;
    bottom: 0;
}

image-tile {
    .btn ng-transclude {
        .badge {
            border-radius: 0;
            right: 0;
            width: 40px;
            margin-left: -40px;
            padding: 4px;
            // position:absolute;
            // right:0;
        }
    }
}
