* {
    padding: 0;
    margin: 0;
}

body,
th,
td,
.btn,
.form-control {
    font-family: "AvenirNext", "Arial";
    font-size: 20px;
}
body {
    overflow-x: hidden;
    background-color: $primary;
}

.nopad {
    padding: 0;
}

#tabletContent {
    margin-top: 7em;
}

.kitchen-debug {
    opacity: .2;
}

.btn.btn-primary {
    background-color: $primary;
    border: none;
    border-radius: 1em;
    padding: 0.5em;
    color: $white;

    &.disabled,
    &.ng-disabled {
        opacity: 0.5;
    }
}

.btn {
    &:focus {
        background-color: gold;
        box-shadow: 0 0 10px solid green;
    }
}

    
.dropdown-menu {
    color: $primary;  
    a, a:hover, a:active, a:visited {
        color: $primary; 
    }
}

@media (orientation: portrait) {
    .hidden-portrait {
        display: none;
    }
}

.dropdown-menu {
    position: absolute;
    text-align: center;
    li a {
        font-size: 20px;
        line-height: 40px;
    }

    li + li {
        border-top: 0.1em solid $grey;
    }
}