qm-spot {
    display: block;

    .leave-QmSpot {
        position: absolute;
        z-index: 9999;
        top: 1em;
        left: 250px;
        width: 300px;
        height: 1.5em;
        color: $primary-dark;
        a, :visited, :active, :focus {
            color: $primary-dark;
            text-decoration: none;
            font-weight: bold;
            font-size: 1em;
            &:before {
                content: "<< ";
                font-weight: bold;
                font-size: 1em;
            }
        }
    }
}
