bug-report {
    display:block;

    ul.search-results {
        list-style-type:none;

        margin:0;
        padding: 0;
        border:1px solid #ddd;

        li{
            &:nth-child(odd) {
                background-color:#fafafa;
            }

            +li {
                border-top:1px solid #ddd;
            }

            a {
                padding:.5rem;
                padding-left:.9rem;
                display: block;

                color:#222;
                border-radius:0;

                &:hover {
                    padding-left:.5rem;
                    border-left:0.4rem solid $green;
                    color:$green;
                }
            }
        }
    }

    .glyphicon-send{
        margin-right: 5px;
    }
}