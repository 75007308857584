calendar-icon {
    display: block;
    div {
        display: inline-block;
        position: relative;

        i {
            position: absolute;
            top: -1.3rem;
            left: -0.95rem;
        }

        span {
            font-size: 1.5rem;
            font-weight: bold;
        }
    }
}
