day-selector {
    display: block;
    width: 27em;

    > ul {
        //width: 50rem;
        list-style-type: none;
        margin: 0;
        padding: 0;

        display: flex;
        flex-grow: row;

        > li {
            border: 1px solid #ddd;
            + li {
                border-left: none;
            }
            //width: 16.5%;

            &.active,
            :hover {
                background-color: $primary-light;
            }

            &.active > a > span,
            > a:hover > span,
            > a:focus > span {
                color: white;
                text-decoration: none;
            }

            > a {
                padding: 0;
                text-align: center;
                color: black;
                > div {
                    color: black;
                    display: inline-block;
                    min-width: 8rem;
                    color: #fff !important;
                }

                display: inline-block;
                background-color: transparent;
                text-decoration: none;
            }
        }
        background-color: $primary;
    }
    background-color: $primary;
}
