h1,
h2, 
h3,
h4,
h5 {
    color:$primary;
}

h2 {
    font-size:1.5em;
    margin-bottom: 1em;
    margin-top:2em;
}