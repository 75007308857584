product-stock {
    display: block;
    padding-top: 0.01rem;

    production-header {
        position: fixed;
        width: 100%;
        top: 7rem;
    }

    > article {
        margin-top: 5.5rem;
        display: flex;

        > aside {
            display: block;
            width: 19.5%;
            margin-top: 0.5rem;
        }

        > section {
            width: 80.5%;

            #job-groups {
                margin: -1rem 1.2rem 0 1.2rem;
            }
            spacer{
                position: relative;
            }
        }

        @media (orientation: portrait) {
            > aside {
                width: 25%;
            }

            > section {
                width: 75%;
            }
        }
    }
}
