@mixin coloredJob($className, $oddColor, $fontColor) {
    production-job-header.#{$className} {
        .job-amount {
            color: $fontColor;
        }
    }

    production-job-header.#{$className},
    timeline.#{$className} {
        border-left: 1.6rem solid $oddColor;
    }

    &:nth-child(even) {
        production-job-header.#{$className},
        timeline.#{$className} {
            border-left: 1.6rem solid darken($oddColor, 10%);
        }
    }
}

job-group {
    display: block;

    > card {
        width: 100%;
    }

    & + job-group {
        > card {
            margin-top: 1.5rem;
        }
    }

    header {
        margin-top:2rem;
        margin-bottom:.5rem;
        display: block;
        padding-left: 2.75rem;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        // border-bottom: 0.2rem solid #d0d0d0;

        h2 {
            font-family: "AvenirNext", "Arial";
            font-size: 2.3rem;
            margin: 0;
            padding: 0;
            color: $black;
            span:first-child {
                width: 7rem;
                margin-right: 0.4rem;
                text-align: left;
                display: inline-block;
            }
        }

        img {
            width: 6rem;
            height: 6rem;
            box-shadow: 0 0 .5rem #444;
        }
    }

    production-job + production-job {
        border-top: 0.1rem solid #bbb;
    }

    production-job {
        background-color: #fdfdfd;

        &.stockQuestion {
            background-color: #fad570;

            &:nth-child(even) {
                background-color: #fac83d;
            }
        }

        &:nth-child(even) {
            background-color: #f6f6f6;
        }

        @include coloredJob("green", #34ca77, #000);
        @include coloredJob("yellow", #e0e044, #000);
        @include coloredJob("orange", #e09977, #000);
        @include coloredJob("red", #e04444, #e00034);

        @include coloredJob("purple", #5d24a7, #5d24a7);
    }
    section {
        margin-top: 0;
    }
}
