keypad {
    display: inline-block;
    ul {
        text-align: center;
        margin:0;
        padding:0;
        display: inline-block;
        list-style-type: none;
        max-width:14.5em;
        li {
            display: inline;
            a.btn {
                padding:1em;
                width:4.25em;
                line-height:1em;
                // width: 1em;
                // height: 1em;
                margin-right:0.5em;
                margin-bottom:0.5em;
            }
        }
    }
}