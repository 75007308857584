.av-icon {
    @mixin icon-size($size) {
        font-size: $size;
        margin-bottom: -($size/2);
        width: $size;
        height: $size;
    }
    @mixin color($name, $foreground, $background: "transparent") {
        &.#{$name} {
            color: $foreground;
            @if $background != "transparent" {
                background-color: $background;
            }
        }
    }
    color: $black;
    @include icon-size(1.5em);
    &.av-icon-circle {
        border-radius: 50%;
        @include icon-size(2.5em);
        background-color: $black;
        color: $white;
        @include color("white", $black, $white);
        @include color("green", $white, $green);
        @include color("orange", $white, $orange);
        @include color("red", $white, $red);
        @include color("blue", $white, $blue);
        @include color("beige", $beige);
    }
    &.disabled {
        cursor: default;
        opacity: 0.3;
    }
}


@font-face {
    font-family: AvenirNext;
    src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}
@font-face {
    font-family: AvenirNext;
    src: url("../fonts/AvenirNextLTPro-Bold.otf") format("opentype");
    font-weight: bold;
}
@font-face {
    font-family: AvenirNext;
    src: url("../fonts/AvenirNextLTPro-It.otf") format("opentype");
    font-style: italic;
}

.typcn {
    &:before {
        color: inherit;

        position: relative;
        font-size: 3.5rem;
    }

    &.circled {
        &:before {
            top: -.8rem;
            left: 0rem;
            font-size: 4rem;
        }
        margin-top: 1rem;
        border-radius: 50%;
        border-width: 3px;

        border-style: solid;
        border-color: $primary-light;
        display: inline-block;
        font-size: 4rem;
        width: 5rem;
        height: 5rem;
    }
}
