toggle-plattenliste-button {
    background-color: $primary-dark;

    button {
        background-color: $primary-dark !important;
        border-top-left-radius: 1em !important;
        border-top-right-radius: 1em !important;
        position: relative;
        .shortcut-label {
            display: block;
            position: absolute;
            text-align: center;
            font-size: 1rem;
            text-transform: none !important; 
            top:1.2rem;
            color:#c0c0c0;
        }
    }
    
}