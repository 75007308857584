main-menu {
    display: block;
    text-align: center;
    margin-top:5em;
    background-color: $secondary-light;
    height: 100%;
    color: white;

    h2 {
        color: $primary;
        margin: 0;
        padding: 1rem 0 0 0;
    }

    ul {
        list-style-type: none;
        margin-bottom: 0;

        li {
            display: inline-block;
            margin: 0.3em;
            margin-bottom: 0;
        }
    }
}
