notifications {
    position: fixed;
    bottom: 0;
    right: 0;
    padding-bottom: 0.5em;
    padding-right: 0.5em;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            @include shadow();
            background-color: #222;
            color: $white;
            padding: 0.5em 1em;
            font-size: 0.8em;
            min-width: 400px;
            &.success,
            &.error,
            &.info {
                border-left: 0.25em solid grey;
            }
            &.success {
                border-left-color: $green;
            }
            &.error {
                border-left-color: $red;
            }
            &.info {
                border-left-color: $blue;
            }
            span {
                margin-left: 1em;
                border-left: 1px solid darkgrey;
                padding-left: 1em;
                a {
                    color: $white;
                }
            }
        }
        li + li {
            margin-top: 0.3em;
        }
    }
}
