recipe {
    display: block;

    .out-of-stock {
        > i:before {
            font-size: 2rem;
        }
        color: red;
    }
    
    .badge {
        margin: 3px;
        font-size: 1em;

        &.warning {
            background-color: $orange;
        }
        &.danger {
            background-color: $red;
        }
    }

    .av-button {        
        &.secondary {
            display: inline;
            padding: 5px;
            position: relative;
            top: 2px;
            border: 1px solid $primary;
            border-radius: 5px;
        }
    }

    .nav.nav-tabs>li.uib-tab.freshnessInfo>a {
        margin: 2px;
        padding: 7px;
        border: 2px solid $orange;
        border-radius: 15px;
    }

    quantity-calculator {
        display: inline-block;
        position: relative;
        top:.8rem;

        .input-group {
            .input-group-addon,  input[type="number"] {
                box-shadow: 0 0 0.25rem #888;
            }

            .input-group-addon {
                border-radius:0;
                font-size: 1.8rem;
                top:-.2rem;
                position: relative;
            }

            input[type="number"] {
                text-align: center;               
            }
        }

        .time-prediction {
            opacity: 0.4;
            display: block;
            
            width:100%;
            text-align: center;
            position: absolute;
            top:-2.6rem;
            font-weight: normal;
            color:#aaa;
            font-size: 1.8rem;
            time {
                color:#555;
                font-weight: bold;
            }
        }
    }

    .table {
        tr {
            th {
                &:nth-child(2),
                &:nth-child(4) {
                    border-left: 1px solid #c0c0c0;
                }                
                vertical-align: middle;
            }
            td {
                &:nth-child(2) {
                    border-left: 1px solid #c0c0c0;
                }
                &.with-border {
                    border-left: 1px solid #c0c0c0;
                }                  
                vertical-align: middle;
            }

            button,
            input {
                
                border: none;
            }

            button {
                width: 5rem;
            }
            
            input {
                width: 7rem;
                text-align: right;
                padding-right: 0.2rem;
            }
        }
    }
}
