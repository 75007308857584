image-tile {

    display: inline-block;
    background-color: $primary;

    margin: 0.8rem;
    padding: 0.8rem;
    border-radius: 20px;

    &.active, &:focus {
        background-color: $primary;

        .btn {
            border:.1em solid $primary;
        }

    }
    .btn {
        border-radius: 20px;
        display: inline-block;
        background-color: $primary;
        border-color: $primary;
        width: 10em;
        height: 10em;        
        .threedots {
            position: relative;
            top: -10px;
            color: white;
            font-size: 9.25rem !important;
            font-weight: bold !important;
            font-style: normal !important;
            font-family: "AvenirNext", "Arial";
            height: 0px !important;
        }
        img {
            position: relative;
            top: -0.2em;
            width: 6em;
            height: 6em;
            margin: 0;
        }
        i {
            position: relative;
            top: -0.2em;
            width: 6em;
            height: 6em;
            font-size: 4em;
            color: white;
            margin: 0;       
 
            &:before {
                color: inherit;
                position: relative;
                font-size: 10rem;
            }
        }
        p {
            position: relative;
            bottom: -7.5em;
            width: 100%;
            overflow: hidden;
            display: block;
            text-align: center;
            color: $secondary;
            text-transform: capitalize; 
            // text-transform: uppercase;
            margin: 0;
            padding: 0;
            line-height: 1em;
            text-overflow:ellipsis;
            font-weight: bold;
        }
        &:hover {
            background-color: $primary;
            border-color: $primary-light;
        }
    }
    

}