production-settings {
    display: block;
    padding: 0 1rem;
    font-size: 1.5rem;
    min-width: 50rem;

    .row {
        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }

        > *:first-child {
            text-transform: capitalize; 
            // text-transform: uppercase;
            color: #888;
        }

        padding: 2rem 0;

        + .row {
            border-top: 0.1rem solid #e0e0e0;
        }
    }
    .filter > div{
        margin: 0 0 10px;
    }
}
