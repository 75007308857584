[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 4rem;
    padding-right: 1.5rem;
    cursor: pointer;
    line-height: 3rem;
    display: inline-block;
    color: black;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 3.2rem;
    height: 3.2rem;
    border: 0.2rem solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    background: $primary-light;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
