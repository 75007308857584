auth-pin {
    display: block;
    text-align: center;
    margin-top:8em;
    background-color: $primary;
    height: 92vh;
    color: white;
    
    h2 {
        color: white;
    }

    #pin {
        list-style-type: none;
        margin: 1em 0;
        padding: 0;
        li {
            margin-right: 0.5em;
            display: inline-block;
            padding: 0.5em 0.75em;
            line-height: 1em;
            @include shadow();
            font-size: 1.5em;
            background-color: white;
            color: $primary;

            &:before {
                content: "\25CC";
            }

            &.active:before {
                content: "\25CF";
            }
        }
    }
}
