card {
    display: block;
    background: #fff;
    border-radius: 2px;
    font-family: "AvenirNext", "Arial";
    text-align: left;

    @include shadow();

    > &:hover {
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25),
            0 5px 5px rgba(0, 0, 0, 0.22);
    }

    &.primary {
        card-title {
            border-top: 0.4rem solid $primary;
        }
    }

    card-title {
        font-size: 2.5rem;
        color: $primary;
        text-transform: capitalize; 
        // text-transform: uppercase;
        font-family: "AvenirNext", "Arial";
        font-weight: bold;
        display: block;
        padding: 1.5rem 2rem 1.5rem 1rem;
        margin-bottom: 0.5rem;
    }

    card-content {
        display: block;
        padding: 0 2rem 2rem 1rem;

        &.spaced-content {
            padding:6rem 2rem;
        }
    }

    + card {
        margin-top:1rem;
    }
}
