connection-indicator {
    display: inline-block;
    width: 6rem;
    height: 3rem;

    &.loading a .wifi-symbol .wifi-circle {
        -o-animation: wifianimation 3s infinite;
        -moz-animation: wifianimation 3s infinite;
        -webkit-animation: wifianimation 3s infinite;
        animation: wifianimation 3s infinite;
    }

    &:not(.loading) {
        a .wifi-symbol .wifi-circle {
            opacity: 1;
        }
    }

    a {
        &.red {
            .wifi-circle,
            .wifi-circle.fourth {
                border-color: red;
            }
        }
        &.orange {
            .wifi-circle,
            .wifi-circle.fourth {
                border-color: orange;
            }
        }
        &.green {
            .wifi-circle,
            .wifi-circle.fourth {
                border-color: lightgreen;
            }
        }
        text-decoration: none;

        .wifi-symbol {
            position: relative;
            right: -2rem;

            [foo],
            & {
                display: block;
                position: absolute;
                // top: 50%;
                display: inline-block;
                width: 3rem;
                height: 3rem;
                margin-top: -6.65rem;
                margin-right: -3rem;

                -ms-transform: rotate(-45deg) translate(-100px);
                -moz-transform: rotate(-45deg) translate(-100px);
                -o-transform: rotate(-45deg) translate(-100px);
                -webkit-transform: rotate(-45deg) translate(-100px);
                transform: rotate(-45deg) translate(-100px);
            }
            .wifi-circle {
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                display: block;
                width: 100%;
                height: 100%;
                font-size: 0.7rem;
                position: absolute;
                bottom: 0;
                left: 0;
                border-style: solid;
                border-width: 0.75em 0.75em 0 0;
                -webkit-border-radius: 0 100% 0 0;
                border-radius: 0 100% 0 0;

                opacity: 0;

                &.first {
                    width: 4em;
                    height: 4em;
                    -o-animation-delay: 800ms;
                    -moz-animation-delay: 800ms;
                    -webkit-animation-delay: 800ms;
                    animation-delay: 800ms;
                }
                &.second {
                    width: 3em;
                    height: 3em;
                    -o-animation-delay: 400ms;
                    -moz-animation-delay: 400ms;
                    -webkit-animation-delay: 400ms;
                    animation-delay: 400ms;
                }
                &.third {
                    width: 2em;
                    height: 2em;
                }
                &.fourth {
                    width: 1em;
                    height: 1em;
                    opacity: 1;
                    -o-animation: none;
                    -moz-animation: none;
                    -webkit-animation: none;
                    animation: none;
                }
            }
        }

        @-o-keyframes wifianimation {
            0% {
                opacity: 0.4;
            }
            5% {
                opactiy: 1;
            }
            6% {
                opactiy: 0.1;
            }
            100% {
                opactiy: 0.1;
            }
        }
        @-moz-keyframes wifianimation {
            0% {
                opacity: 0.4;
            }
            5% {
                opactiy: 1;
            }
            6% {
                opactiy: 0.1;
            }
            100% {
                opactiy: 0.1;
            }
        }
        @-webkit-keyframes wifianimation {
            0% {
                opacity: 0.4;
            }
            5% {
                opactiy: 1;
            }
            6% {
                opactiy: 0.1;
            }
            100% {
                opactiy: 0.1;
            }
        }
    }
}
