production-planning-suppliers {
    display: block;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 1rem 0rem;

            & + li {
                border-top: 0.1rem solid #979797;
            }
        }
    }
}
