search {
    display: block;

    margin:-5px;
    margin-bottom: 0;
    padding:8px 8px 8px 5px;
    background-color:$darkGray;

    span.input-group-addon {
        background-color:$darkGray;
        border:0;
        border-radius:0;
        padding-right:16px;
        i.glyphicon {
            color:$white;
            font-size: 2em; 
        }
    }

    input[type="text"] {
        border-radius: 0;
        font-size: 1.2em;
        line-height: 2em;
        height:2em;
        border:0;
    }


}